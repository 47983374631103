import React from 'react';

import { Link } from 'react-router-dom';

const Plan = ({ title, price, description, items, allowPreOrder, type }) => {
  return (
    <>
      <div className="bg-white price-block mb-3 mb-sm-0 col-lg-4 col-md-6 col-sm-12">
        <div className="inner-box">
          <div className="title-box">
            <h2>{title}</h2>
          </div>
          <div className="content-box">
            <div className="price">
              {price} <span>/Mes</span>
            </div>
            <div className="title">{description}</div>

            <ul className="price-list">
              {items.map((element, index) => {
                console.log(element.added);
                return (
                  <li
                    key={'Ne' + index}
                    className={(element.added ? 'check' : 'cross')}
                  >
                    <span
                      className={
                        element.added
                          ? ' fa fa-check check'
                          : ' fa fa-remove remove'
                      }
                    />
                    {element.description}
                  </li>
                );
              })}
            </ul>

            {/*<div className="mb-1">*/}
            {/*  <Link*/}
            {/*    to={`/Payment/${type}`}*/}
            {/*    className="theme-btn btn-style-four"*/}
            {/*  >*/}
            {/*    Pre suscribirse*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
