import React from 'react'

function Endomedic({ props }) {
  return (
    <>
      <section className='banner-section-three'>
        <div className='auto-container'>
          <div className='row clearfix'>
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column wow slideInLeft'>
                <h2>
                  Somos
                  <br /> Endocrinólogos pediatras del centro médico
                  Endociencia SAS
                </h2>
                <div className='text'>
                    Nuestro mayor aliado es la tecnología para
                    promover los hábitos saludables que día a día recomendamos a nuestros pacientes.
                </div>
              </div>
            </div>

            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div
                className='paroller'
                data-paroller-factor='0.15'
                data-paroller-factor-lg='0.15'
                data-paroller-factor-md='0.10'
                data-paroller-factor-sm='0.10'
                data-paroller-type='foreground'
                data-paroller-direction='horizontal'
              >
                <img
                  loading='lazy'
                  src={require('../public/images/resource/mokeup-11.png')}
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {false && (
        <section className='services-section'>
          <div className='auto-container'>
            <div className='sec-title centered'>
              <h2>
                 Con ayuda de la tecnología de forma lúdica promovemos hábitos saludables
                 a distancia.
              </h2>
            </div>

            <div className='row clearfix'>
              <div className='services-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Dra. Paola Durán Ventura</h3>
                  <div className='text'>
                    Médico Cirujano de la Pontificia Universidad Javeriana.
                    Especializada en Pediatría en la Universidad de Miami
                    Jackson Memorial Hospital. Especializada en Endocrinología
                    Pediátrica en la Universidad de Miami y en la Universidad de
                    Berna Suiza.
                  </div>
                </div>
              </div>

              <div className='services-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='300ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Dr. Mauricio Coll Barrios</h3>
                  <div className='text'>
                    Médico Cirujano de la Pontificia Universidad Javeriana.
                    Especializado en Pediatría y en Endocrinología Pediátrica en
                    la Universidad Claude Bernard de Lyon.
                  </div>
                </div>
              </div>

              <div className='services-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='600ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Camila Céspedes Salazar</h3>
                  <div className='text'>
                    Médico Cirujano especializada en Pediatría de la Pontificia
                    Universidad Javeriana. Especializada en Endocrinología
                    Pediátrica en la Universidad Claude Bernard de Lyon,
                    Francia.
                  </div>
                </div>
              </div>

              <div className='services-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='900ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Dra. Catalina Forero Ronderos</h3>
                  <div className='text'>
                    Médico Cirujano especialista en Pediatría de la Pontificia
                    Universidad Javeriana. Especializada en Endocrinología
                    Pediátrica en la Universidad Autónoma de México DF, México.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {true && (
        <section className='services-section'>
          <div className='auto-container'>
            <div className='sec-title centered'>
              <h2>
                              Endoforce es ideal para acompañar a distancia la adopción de
                              hábitos saludables a través del juego
              </h2>
            </div>

            <div className='row clearfix'>
              <div className='services-block col-lg-6 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Actividad todos los días</h3>
                  <h4 className='text h3'>
                    Le recordamos a su hijo/a durante el día los hábitos
                    saludables a seguir.
                  </h4>
                </div>
              </div>

              <div className='services-block col-lg-6 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='300ms'
                  data-wow-duration='1500ms'
                >
                  <h3>La tecnología es nuestra aliada</h3>
                  <h4 className='text h3'>
                    Utilizamos los dispositivos para detectar el ritmo de juego
                    y su actividad en altas horas de la noche.
                  </h4>
                </div>
              </div>

              <div className='services-block col-lg-6 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='600ms'
                  data-wow-duration='1500ms'
                >
                  <h3>Vamos día a día</h3>
                  <h4 className='text h3'>
                    Definimos objetivos para cada día, así promover la
                    adopción de hábitos de forma continua.
                  </h4>
                </div>
              </div>

              <div className='services-block col-lg-6 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='900ms'
                  data-wow-duration='1500ms'
                >
                  <h3>El seguimiento es en línea</h3>
                  <h4 className='text h3'>
                    En nuestra suscripción anual, compartimos los resultados en una plataforma 100% en línea.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className='services-section-two'>
        <div className='auto-container'>
          <div className='sec-title centered'>
            <h2>¿Por qué crear Endoforce?</h2>
          </div>

          <div className='services-block-two style-two'>
            <div className='inner-box'>
              <div className='row clearfix'>
                <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                  <div
                    className='inner-column wow fadeInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <div className='icon-box'>
                      <div className='service-number'>01</div>
                      <span className='icon flaticon-light-bulb'></span>
                    </div>
                    <h3>Impacto global</h3>
                    <div className='text'>
                      Instituciones educativas como la{' '}
                      <a href='https://daneshyari.com/article/preview/350199.pdf'>
                        Universidad
                      </a>{' '}
                      de Laguna en España comprobaron que sincronizar los
                      aspectos, saludables emocionales, psicológicos y
                      recreativos mediante videojuegos pueden afectar el estilo
                      de vida de los niños y jóvenes a tal punto de acompañarlos
                      en sus hábitos saludables.
                    </div>
                  </div>
                </div>

                <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                  <div
                    className=' wow fadeInRight'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <div className='image'>
                      <img
                        loading='lazy'
                        src={require('../public/images/web_dos.gif')}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='services-block-three style-two'>
            <div className='inner-box'>
              <div className='row clearfix'>
                <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                  <div
                    className='wow fadeInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <div className='image'>
                      <img
                        loading='lazy'
                        src={require('../public/images/web_uno.gif')}
                        alt=''
                      />
                    </div>
                  </div>
                </div>

                <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                  <div
                    className='inner-column wow fadeInRight'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <div className='icon-box'>
                      <div className='service-number'>02</div>
                      <span className='icon flaticon-chat-1'></span>
                    </div>
                    <h3>Innovación</h3>
                    <div className='text'>
                      <a href='https://www.tandfonline.com/doi/full/10.1080/01947648.2017.1303409'>
                        The Journal of Legal Medicine
                      </a>{' '}
                      , respalda varios artículos que insisten en proponer a los
                      padres de familia en no pensar en la tecnología como el único
                      factor del sedentarismo y por el contrario aceptar que  es el medio del
                      siglo XXI para atacar el problema.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Endomedic
