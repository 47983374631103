import React, { Component } from 'react'
export class TermsOfService extends Component {
    render() {
        return (
            <>
                <div
                    className='container'
                    style={{ paddingTop:  this.props.top ?? '15rem', paddingBottom: '10rem' }}
                >
                    <div style={{ wordWrap: 'break-word' }}>
                        <div className='WordSection1'>
                            <h1 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    TÉRMINOS Y CONDICIONES PARA LA PRESUSCRIPCIÓN DEL SERVICIO DE VIDEOJUEGO PARA DISPOSITIVOS MÓVILES “ENDOFORCE”
                                </span>
                            </h1>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Antes de efectuar cualquier tipo de transacción electrónica,
                                    por favor lea detalladamente estas políticas de Presuscripción.
                                    Lo aquí expuesto será de acatamiento obligatorio para usuarios/suscriptores
                                    y ENDOCIENCIA SAS, al decidir la «Presuscripción» del videojuego, usted
                                    declara conocer y aceptar las condiciones y limitaciones establecidas en
                                    este documento:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    &nbsp;
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    1. DEFINICIONES:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '11.0pt',
                                        lineHeight: '80%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Presuscripción:  
                                </span>
                                <span>
                                    Es el pago anticipado que el usuario/suscriptor,
                                    realiza por alguno de los planes de suscripción del
                                    videojuego ENDOFORCE antes de que este sea lanzado
                                    en las tiendas de aplicaciones de Android y iOS en
                                    la fecha estimada.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Sitio:
                                </span>
                                <span>
                                    Es el sitio web de ENDOFORCE cuyo link de acceso es www.endorce.co
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                   Endoforce:
                                </span>
                                <span>
                                    Es el servicio de videojuego para dispositivos móviles que presta
                                    ENDOCIENCIA a los Usuarios y/o Suscriptores registrados a través
                                    del Sitio y la aplicación móvil ENDOFORCE, el cual incentiva hábitos
                                    saludables para niños, niñas y adolescentes dentro del territorio
                                    Colombiano, de la mano de endocrinólogos pediatras de ENDOCIENCIA SAS.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Transacción electrónica:
                                </span>
                                <span>
                                    Entiéndase como la transferencia de dinero hecha por el suscriptor
                                    a ENDOCIENCIA SAS por el concepto de presuscripción del servicio de
                                    video juego para dispositivos móviles “ENDOFORCE”, haciendo uso de
                                    medios electrónicos tales como: tarjeta de crédito, débito, Epayco
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Usuario/ Suscriptor:
                                </span>
                                <span>
                                    Es aquella persona natural, mayor de 18 años que decide presuscribirse
                                    a el servicio de videojuego para dispositivos móviles “ENDOFORCE” ofrecido
                                    a través del presente sitio web.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Proveedor:
                                </span>
                                <span>
                                    ENDOCIENCIA SAS, identificado con NIT No. 901.133.184-6,
                                    es el único proveedor del servicio de videojuego para dispositivos
                                    móviles “ENDOFORCE” ofertado y comercializado dentro del sitio
                                    web www.endorce.co
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Derecho de retracto:
                                </span>
                                <span>
                                    Facultad de arrepentimiento del usuario/suscriptor,
                                    en razón a su la libertad de cambiar la motivación
                                    de compra (presuscripción).
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Reversión del pago:
                                </span>
                                <span>
                                    Es un mecanismo legal que protege los derechos de los consumidores
                                    o clientes que compran productos o servicios a través de comercio
                                    electrónico y canales no presenciales, permitiéndoles solicitar
                                    el reembolso de lo pagado, a cambio de la devolución del producto
                                    comprado (cuando aplique), siempre que se presente 1 de los 5 casos
                                    que mencionamos más adelante y se cumplan algunos requisitos.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    1. PROCESO DE PRESUSCRIPCIÓN:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Es la opción que tiene el usuario/suscriptor para suscribirse
                                    a cualquiera de los dos planes que el proveedor ofrece de su
                                    servicio de videojuego para dispositivos móviles “ENDOFORCE”
                                    antes de su lanzamiento; dicho monto se entenderá como el pago
                                    del valor total del servicio, el cual podrá empezar a disfrutarse
                                    a partir de la fecha en la que se le haya informado previamente
                                    por el proveedor.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    1.1. Fechas para la realización de la presuscripción del servicio:   
                                </span>
                                <span>
                                    El usuario/suscriptor podrá realizar su presuscripción al servicio de
                                    videojuego para dispositivos móviles “ENDOFORCE”, para cualquiera de
                                    sus dos planes, en el sitio web www.endorce.co a partir de las 0:00
                                    horas (hora Colombia) del 1 de octubre del 2021 hasta las 11:00pm
                                    (hora Colombia) del 29 de noviembre del 2021.
                                    El código de acceso al videojuego será enviado a más tardar a las
                                    24hrs siguientes a la realización del pago del servicio, al correo
                                    electrónico que el usuario/suscriptor haya relacionado para tal fin.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    1.2. Fecha de lanzamiento y acceso al servicio de videojuego para dispositivos móviles “ENDOFORCE”:
                                </span>
                                <span>
                                    El usuario/suscriptor podrá acceder al servicio de videojuego para dispositivos móviles “ENDOFORCE”
                                    a través de las tiendas de aplicación para Android (Play Store) o  iOS(App Store) a partir de las
                                    0:00 horas (hora Colombia) del 30 de Noviembre del 2021 y por el término de 6 o 12 meses (de acuerdo
                                    con el plan adquirido por el usuario) con el código de acceso que le fue previamente enviado luego
                                    de realizar el pago de su presuscripción.
                                </span>
                            </p>



                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    2. VARIACIÓN EN LAS FECHAS DE LANZAMIENTO Y ACCESO AL VIDEOJUEGO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Se advierte al usuario/suscriptor que la fecha del lanzamiento y
                                    acceso al videojuego puede sufrir variaciones; no obstante, tal
                                    situación será previamente informada a los usuarios en aras de que:
                                    i). puedan manifestar si se acogen a esta nueva fecha, continuando
                                    con el tiempo de espera, o ii) soliciten la devolución de su dinero.
                                    Si el usuario/suscriptor decide la opción número dos, es decir la
                                    devolución de su dinero, éste será contacto en un término no mayor
                                    a cinco (5) días por uno de nuestros asesores para indicarle el
                                    proceso a seguir.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    3.  CONDICIONES MÍNIMAS PARA ACCEDER AL SERVICIO Y OTRAS DISPOSICIONES DE USO:
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    3.1 Información de las condiciones mínimas para acceder al servicio de videojuego para dispositivos móviles “ENDOFORCE”:
                                </span>
                                <span>
                                    •	Para instalar la aplicación “ENFORCE” se requiere un espacio mínimo de 2GB de memoria en su dispositivo móvil.</span>
                                <span> •	Para la conexión en dispositivos Android (smartphone y tablet) se debe descargar la aplicación gratuita que ofrece Google Play Store. Para una óptima utilización de los sistemas operativos Android, se advierte como requisito mínimo que la versión no puede ser anterior a 7.</span>

                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>


                                <span> •	Para la conexión en dispositivos iOS (smartphone y tablet) se debe descargar la aplicación gratuita de Apple Store. Para una óptima utilización de los sistemas operativos iOS, se advierte como requisito mínimo que la versión no puede ser anterior a 10.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span>    Una vez el usuario/suscriptor descargue la aplicación en el dispositivo, puede acceder al Servicio ingresando el código de acceso que le fue previamente enviado a su correo electrónico luego de realizar el pago de su presuscripción.

                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    4. PROCESO DE PAGO DE LA PRESUSCRIPCIÓN:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    El registro y proceso de pago electrónico del videojuego para
                                    dispositivos móviles “ENDOFORCE, se realizará a través de la
                                    pasarela de pago Epayco, quien se reserva el derecho de rechazar
                                    la transacción electrónica en caso de que evidencie algún riesgo
                                    que pueda afectar la seguridad. Epayco cuenta con todos los
                                    estándares para garantizar la seguridad y confidencialidad de
                                    la información del titular de la tarjeta crédito o débito, para
                                    más información consulta en
                                    https://epayco.com/terminos/politica-privacidad/index.php La
                                    pasarela será responsable de los asuntos relacionados con el
                                    incumplimiento de los estándares que se informan en el link anterior.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    5. PLANES DEL SERVICIO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Cualquier persona natural, mayor de 18 años puede adquirir el servicio de videojuego
                                    para dispositivos móviles “ENDOFORCE” a través de cualquiera de los siguientes planes:
                                    (La variación de ambos planes radica en el término para el disfrute de la suscripción.
                                    Parágrafo único: Ninguno de los dos planes de suscripción poseen renovación automática.)
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    PLAN DE 6 MESES:
                                </span>
                                <span>
                                    • Acceso a todos los minijuegos que incluye la App.
                                    •Trazabilidad de los datos al jugar en línea.
                                    •Retos diarios de actividad física.
                                    •Recordatorios diarios de hábitos saludables.
                                    •Monitoreo a distancia de Endocrinólogos Pediatras.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    PLAN DE 12 MESES:
                                </span>
                                <span>
                                    • Acceso a todos los minijuegos que incluye la App.
                                    •Trazabilidad de los datos al jugar en línea.
                                    •Retos diarios de actividad física.
                                    •Recordatorios diarios de hábitos saludables.
                                    •Monitoreo a distancia de Endocrinólogos Pediatras.
                                </span>
                            </p>



                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    6. DERECHO DE RETRACTO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Al ser la facultad de arrepentimiento que posee el usuario/suscriptor,
                                    este podrá ejercer tal derecho, de conformidad con en el artículo 47
                                    de la Ley 1480 de 2011 (Estatuto del Consumidor), siempre y cuando se
                                    cumplan los siguientes requisitos: a) que dicho derecho sea ejercido
                                    en el plazo de cinco (5) días hábiles a partir de la fecha en que
                                    pagó el Servicio, mediante el envío de una comunicación a través del
                                    correo electrónico facturacion@endoforce.co manifestando que ejerce
                                    el derecho de retracto; y , b) Que dentro de ese plazo de cinco (5)
                                    días hábiles mencionado, el Suscriptor no haya utilizado el Servicio.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    6.1. Trámite: (Para pagos con tarjeta de crédito y débito)
                                </span>
                                <span>
                                    Una vez ENDOCIENCIA SAS verifique que se han cumplido los dos requisitos mencionados, solicitará a la plataforma de pago Epayco la devolución del dinero pagado por el Suscriptor dentro de los treinta (30) días calendario siguientes a la fecha en que haya ejercido el derecho de retracto.
                                    Para las tarjetas de crédito, el Suscriptor deberá hacer seguimiento en su banco sobre la devolución del dinero toda vez que cada banco tiene diferentes políticas para estas instancias (unos abonan el dinero al cupo de la tarjeta y otros abonan a la deuda).
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Si alguno de los dos requisitos no se cumple, no habrá lugar a la devolución del dinero.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    7. REVERSIÓN DEL PAGO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    El usuario/suscriptor podrá solicitar la reversión del pago según
                                    corresponda el caso, a los cinco (5) días siguientes dentro de los
                                    cuales concurra alguna de las siguientes circunstancias: (i) cuando
                                    el Suscriptor sea objeto de un fraude; (ii) cuando el pago del
                                    Servicio corresponda a una operación no solicitada; (iii) cuando
                                    el Servicio no sea recibido; (iv) cuando el Servicio no corresponda
                                    a lo solicitad; es decir que no cumpla con las características que
                                    le fueron informadas por el proveedor, y (v) cuando el Servicio sea
                                    defectuoso.

                                    Para que proceda la reversión del pago, dentro los cinco (5) días
                                    hábiles siguientes a la fecha en que haya ocurrido cualquiera de las
                                    siguientes situaciones, el usuario/suscriptor deberá presentar queja
                                    ante ENDOCIENCIA SAS al correo: facturacion@endoforce.co  indicando:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span> • Nombre e identificación.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Asunto: Solicitud de reversión del pago y causal invocada.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Identificación del plan adquirido y fecha de adquisición.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Medio de pago (identificación de la cuenta bancaria, tarjeta de crédito o instrumento de
                                    pago al que fue cargada la operación).</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Valor por el que se solicita la reversión.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Hechos que sustentan la solicitud y las pruebas que soportan a la misma.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>


                                <span> • Causal invocada para la reversión del pago</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Datos de contacto (dirección, teléfono, celular, correo electrónico).</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>
                              
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Cada entidad financiera suele tener un formulario específico para
                                    la presentación de la solicitud de reversión del pago, en esa medida,
                                    se recomienda consultar en la página de internet de su banco.

                                    Una vez recibida la solicitud de reversión del pago, ENDOCIENCIA SAS
                                    notificará al emisor del instrumento de pago electrónico (entidad bancaria),
                                    quien tendrá un término de quince (15) días hábiles para dar trámite a
                                    la solicitud.

                                    El banco deberá suministrar al usuario/suscriptor la información de la
                                    transacción reversada; si surgen controversias dentro del trámite de la
                                    solicitud de reversión del pago y se llega a determinar que la misma no
                                    es procedente, ENDOCIENCIA SAS notificará al usuario/suscriptor de las
                                    razones de hecho y derecho que sustentan la negativa.

                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                   8. ACUERDO PARA RECIBIR COMUNICACIONES ELECTRÓNICAS
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Al suscribirse a Endoforce acepta recibir notificaciones por
                                    parte de Endoforce de manera electrónica a la dirección de
                                    correo electrónico que nos proporcione. Usted acepta que todos
                                    los avisos, divulgaciones y otras comunicaciones que le
                                    enviemos de forma electrónica satisfacen los requisitos
                                    legales de que tales comunicaciones sean por escrito. Acepta
                                    que puede almacenar dichas comunicaciones para que usted pueda
                                    acceder a ellas y sin cambios. Acepta mantener su información
                                    de correo electrónico actualizada.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    9. TERRITORIALIDAD DEL SERVICIO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Si el usuario/suscripto está domiciliado en Colombia los
                                    “Términos y Condiciones” se encuentran sujetos y regidos
                                    por las leyes vigentes en el territorio Colombiano en
                                    materia del Derecho del Consumo y demás disposiciones.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <style jsx>{`
          @font-face {
            font-family: 'Cambria Math';
            panose-1: 2 4 5 3 5 4 6 3 2 4;
          }

          @font-face {
            font-family: Calibri;
            panose-1: 2 15 5 2 2 2 4 3 2 4;
          }

          @font-face {
            font-family: 'Arial Nova Light';
            panose-1: 2 11 3 4 2 2 2 2 2 4;
          }

          @font-face {
            font-family: Aparajita;
            panose-1: 2 2 6 3 5 4 5 2 3 4;
          }

          /* Style Definitions */
          p.MsoNormal,
          li.MsoNormal,
          div.MsoNormal {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          h1 {
            mso-style-link: 'Heading 1 Char';
            margin-top: 12pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 0cm;
            line-height: 107%;
            page-break-after: avoid;
            font-size: 16pt;
            font-family: 'Arial Nova Light', sans-serif;
          }

          span.Heading1Char {
            mso-style-name: 'Heading 1 Char';
            mso-style-link: 'Heading 1';
            font-family: 'Arial Nova Light', sans-serif;
            font-weight: bold;
          }

          .MsoChpDefault {
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          .MsoPapDefault {
            margin-bottom: 8pt;
            line-height: 107%;
          }
        `}</style>
            </>
        )
    }
}
