import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const ListQa = {
  Endoforce: [
    {
      title: '¿Qué es Endoforce?',
      description: (
        <p>
          Es un videojuego para dispositivos móviles creado por Endocrinólogos
          Pediatras para promover hábitos saludables en niños, niñas y adolescentes.
        </p>
      )
    },
    {
      title: '¿Cuáles son los requerimientos del móvil o tablet?',
      description: (
        <p>
         Para dispositivos con sistema operativo IOS se requiere una
         versión igual o superior a 10.0. En Android mayor a 7.0. También se requiere conexión de
         internet con tasa de descarga mayor a 5MB
        </p>
      )
    },
    {
      title: '¿Se requiere conexión a internet?',
      description: (
        <p>
          Preferiblemente si, ya que el juego descarga todos los días datos nuevos producto de los retos diarios. En caso de
          que se pierda la conexión, se cuentan con cinco días para que el dispositivo móvil almacene los datos de juego. Así,
          cuándo el jugador retome la conexión a internet, se subirán los datos de los últimos cinco días de juego.
        </p>
      )
    },
    {
      title: '¿Cómo hacen seguimiento a los usuarios?',
      description: (
        <p>
          Medimos el cumplimiento de objetivos diarios. Cada minijuego tiene una
          función específica para adoptar los hábitos saludables.
        </p>
      )
    },
    {
      title: '¿Quién tiene acceso a los datos del usuario?',
      description:
        'Los datos son administrados por Endociencia S.A.S y Linkcode S.A.S empresa encargada de la seguridad de los mismos bajo tecnologías en la nube. Para mayor información ve la política de protección de datos.'
    },
    {
      title: '¿Cada cuanto debe jugar el usuario?',
      description:
        'Recomendamos jugar todos los días con un tiempo entre una a dos horas distribuidas en el día. Esto puede ser variable acorde al juicio del equipo médico científico de Endociencia.'
    },
    {
      title: '¿Qué pasa si no juega el usuario?',
      description:
        'Estamos en constante envío de notificaicones y recordatorios al jugador. En caso tal de no ser contestadas, lo registraremos en la plataforma en línea y al equipo de Endomedic (Endociencia).'
    },
    {
      title: '¿Cuáles son los hábitos a fortalecer?',
      description:
        'El juego se concentra en los siguientes focos: promover habitos de alimentación saludable, control emocional y control del uso del dispositivo móvil.'
    },
    {
      title: '¿En cuanto tiempo se ven los resultados?',
      description:
        'Adoptar un habito toma tiempo. Por eso nuestra suscripción más sencilla es trimestral, aunque desde el primer mes podemos monitorear los resultados en la plataforma de seguimiento en línea.'
    },
    {
      title: '¿Es solo para niños con sobrepeso/obesidad?',
      description:
        'No. Es para niños y niñas entre 7 y 14 años que deseen mejorar sus hábitos a través del juego.'
    },
    {
      title: '¿Cuándo es el lanzamiento?',
      description:
        'Su lanzamiento oficial es el 10 de Febrero de 2024. Se espera su lanzamiento en IOS antes de culminar el primer semestre de 2024..'
    }
  ],
  Endociencia: [
    {
      title: '¿Qué es Endomedic?',
      description:
        'Endomedic es el grupo médico científico dentro del videojuego. En la vida real es el equipo médico de Endociencia interpretando y monitoreando los datos del usuario.'
    },
    {
      title: '¿Cuál es el rol de Endomedic?',
      description:
        'Diseñar estrategias con bases científicas para poyar la adopción de hábitos saludables. Es posible obtener una consulta virtual con el equipo para interpretar los datos para el acudiente o padre de familia que asi lo desee. Podrán obtener hasta un 30% de descuento por ser parte de la comunidad Endoforce.'
    },
    {
      title: '¿Endomedic tiene contacto directo con el usuario en Endoforce?',
      description:
        'No, el equipo únicamente envía notificaciones para apoyar al usuario desde el juego, pero en Endoforce no existe una herramienta directa de comunicación.'
    }
  ],
  Pagos: [
    {
      title: '¿Al pagar la suscripción puedo jugar de inmediato?',
      description:
        'Si, Endoforce no cuenta con un periodo de pruebas, se requiere seleccionar una suscripción trimestral o anual para disfrutar del juego. Podrán conocer más de la historia de Endoforce al descargarlo.'
    },
    {
      title: '¿Por dónde adquiero la suscripción?',
      description:
        'Endoforce utiliza las tiendas oficiales de aplicaciones (Google play y próximamente App Store) para la compra de una de nuestras suscripciones. Solo se debe descargar el juego, registrarse y seleccionar mi suscripciones. Allí podrá seleccionar uno de nuestros planes.'
    },
    {
      title: '¿Endoforce almacena datos de mis tarjetas debito o crédito?',
      description:
        'No, Endoforce usa los sistemas oficiales de pagos de las tiendas de aplicaciones, para la total transparencia y seguridad de la transacción, tanto Google como Apple usan los medios de pagos registrados por el usuario en las tiendas de aplicaciones.'
    },
    {
      title: '¿Realizan devolución del dinero?',
      description:
        'Nos adherimos a la política general de las tiendas de aplicaciones: Si un usuario cancela una suscripción adquirida mediante el sistema de facturación de Google Play, nuestra política general establece que el usuario no recibirá un reembolso por el período de facturación vigente, pero seguirá recibiendo el contenido de la suscripción durante el resto del período de facturación actual, sin importar la fecha de cancelación. La cancelación entra en vigencia cuando finaliza el período de facturación en curso.'
    },
    {
      title: '¿Puedo adquirir una prueba del videojuego?',
      description:
        'Unicamente podras acceder al videojuego adquiriendo una suscripción.'
    },
    {
      title: '¿Cómo puedo hacer el pago de la suscripción?',
      description: (
        <>
          <p>
            Por el momento, Google play recibe tarjetas débito, crédito y tarjetas recargables de Google para cargar a la cuenta de Google Play como saldo.
          </p>
          <a href= 'https://play.google.com/store/paymentmethods?hl=es_CO&gl=US'> Revisa los medios de pago registrados en tu cuenta de Google play. </a>
        </>
      )
      },
      {
          title: '¿Cúando me cobran el juego?',
          description: (
              <>
               <p>
                Dependiendo del plan, se cobra con periodicidad trimestral o anual. El débito es automático y de renovación automática. Para desactivar la opción de renovación automática
                o administrar en general la suscripción se debe ingresar en la tienda de aplicaciones y seleccionar el módulo de suscripciones para desactivar la renovación automático u otros
                procesos con la suscripción.
              </p>
              </>
          )
      },
      {
          title: '¿La suscripción se renueva de forma automática?',
          description:
              'Si, y su debito es automático facturable acorde al plan (Trimestral o anual)'
      },
      {
          title: 'Tengo problemas en el pago de mi plan ¿Qué debo hacer?',
          description:
              'Comunicate de inmediato a nuestros canales de contacto y te brindaremos apoyo en el proceso. WhatsApp (+57) 322 735 07 39, Correo facturacion@endoforce.co'
      },
  ]
}

const QaBlock = ({ title, children }) => {
  return (
    <>
      <div className='faq-block'>
        <div className='inner-box'>
          <h3>{title}</h3>
          <div className='text'>{children}</div>
        </div>
      </div>
    </>
  )
}
const MenuQa = ({ topic, description, onSetSelectedTopic, selectedTopic }) => {
  return (
    <>
      <li
        onClick={onSetSelectedTopic}
        className={'tab-btn ' + (selectedTopic === topic ? 'active-btn' : '')}
      >
        {description}
      </li>
    </>
  )
}
function Faq() {
  const [selectedTopic, setSelectedTopic] = useState('Endoforce')
  return (
    <>
      <section
        className='faq-page-section justify-words major-text-left'
        style={{ paddingTop: '13rem' }}
      >
        <div className='outer-container'>
          <div className='faq-info-tabs'>
            <div className='faq-tabs tabs-box'>
              <ul className='tab-btns tab-buttons clearfix'>
                <MenuQa
                  topic={'Endoforce'}
                  description={'Sobre Endoforce'}
                  selectedTopic={selectedTopic}
                  onSetSelectedTopic={() => {
                    setSelectedTopic('Endoforce')
                  }}
                />
                <MenuQa
                  topic={'Endociencia'}
                  description={'Sobre Endociencia'}
                  selectedTopic={selectedTopic}
                  onSetSelectedTopic={() => {
                    setSelectedTopic('Endociencia')
                  }}
                />
                <MenuQa
                  topic={'Pagos'}
                  description={'Pagos y suscripciones'}
                  selectedTopic={selectedTopic}
                  onSetSelectedTopic={() => {
                    setSelectedTopic('Pagos')
                  }}
                />
              </ul>

              <div className='tabs-content'>
                <div className='' id='faq-started'>
                  <div className=''>
                    <div className=''>
                      {ListQa[selectedTopic].map((e, index) => {
                        return (
                          <QaBlock key={index} title={e.title}>
                            {e.description}
                          </QaBlock>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq
